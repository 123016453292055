import "./styles.css";
import TroubleTicket from "./components/TroubleTickets";
import React, { useState } from "react";

function App() {
  const [showComponent, setShowComponent] = useState(null);

  const _onClick = (name, filter) => {
    if (showComponent !== null && showComponent.filter === filter) {
      setShowComponent(null);
    } else {
      setShowComponent({ filter: filter, name: name });
    }
  };

  const generateTickets = () => {
    if (showComponent) {
      return (
        <div>
          <TroubleTicket
            filter={showComponent["filter"]}
            property={showComponent["name"]}
          ></TroubleTicket>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="body2">
        <header className="body">Trouble Ticket Tracker</header>
        <button className="btn" onClick={() => _onClick("account", "all")}>
          All Tickets
        </button>
        <button className="btn" onClick={() => _onClick("account", "Citrin")}>
          Citrin Tickets
        </button>
        <button
          className="btn"
          onClick={() => _onClick("account", "Carrington")}
        >
          Carrington Tickets
        </button>
        <button
          className="btn"
          onClick={() => _onClick("account", "Slingshot")}
        >
          Slingshot Tickets
        </button>
        {generateTickets()}
      </div>
    </div>
  );
}

export default App;
