import React from "react";
import "./TroubleTickets.css";
import axios from 'axios';
import qs from 'qs';

function TroubleTicket({ property, filter }) {
  const generateTickets = async () => {
    const res = await axios.post('http://code.charpies.com/api.php', qs.stringify({function: 'getTickets'}));

    const List = (res.data).map((ticket) => {
      if (filter !== "all" && filter !== ticket[property]) {
        return <></>;
      } else {
        return (
          <div className="ticket-item" date={ticket.date}>
            <h1 className="ticket-item__date">{ticket.date}</h1>
            <div className="ticket-item__account">{ticket.account}</div>
            <div className="ticket-item__user">{ticket.user}</div>
            <div className="ticket-item__issue">{ticket.issue}</div>
          </div>
        );
      }
    });
    console.log(List);
    return List;  
  };

  return <>{generateTickets()}</>;
}

export default TroubleTicket;
